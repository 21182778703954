import { render, staticRenderFns } from "./_merchant.vue?vue&type=template&id=924f2e9c"
import script from "./_merchant.vue?vue&type=script&lang=js"
export * from "./_merchant.vue?vue&type=script&lang=js"
import style0 from "./_merchant.vue?vue&type=style&index=0&id=924f2e9c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SellersMPSellersTitle: require('/usr/src/app/components/Sellers/MPSellersTitle.vue').default,MarketMPHeader: require('/usr/src/app/components/Market/MPHeader.vue').default,MarketMPProductContainer: require('/usr/src/app/components/Market/MPProductContainer.vue').default})
